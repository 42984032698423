*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}


@font-face {
  font-family: 'db_heavent';
  src: local('db_heavent'), url(./assets/fonts/db_heavent_v3.2-webfont.woff2) format('woff');
}

body {
  font-family:  'db_heavent', sans-serif;
}





